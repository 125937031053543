<template>
  <Sidebar ref="sidebar" :title="title">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1">
        <FieldsGenerator
          v-if="ready"
          :errors="errors"
          :fields="fields"
          :data="form"
          :entryId="entryId"
          :entryType="entryType"
          :fileCollection="form.media"
          class="relative z-1"
        />

        <!--Color picker-->
        <Swatches
          fallback-input-type="color"
          v-model="form.options.color"
          class="swatches bg-white w-full p-4 rounded border border-gray-300"
          show-fallback
          inline
        />
        <!--/Color picker-->

        <div class="form-builder">
          <h2 class="text-xl font-semibold text-prasset-green-500 mt-4">Weergave</h2>
          <div class="flex flex-row justify-between items-center">
            <div class="hint mb-4">De weergave word pas getoond/ververst nadat de wijzigingen zijn opgeslagen</div>

            <a :href="previewUrl" target="_blank">
              <i class="ri-external-link-line text-xl"></i>
            </a>
          </div>
          <iframe
            ref="frame"
            v-if="ready && showFrame"
            :src="previewUrl"
            height="600"
            width="100%"
            frameborder="0"
          >
          </iframe>
        </div>

          <h2 class="text-xl font-semibold text-prasset-green-500 mt-4">Aanbod tonen</h2>
          <MarkdownContent class="text-sm text-prasset-gray-800 mt-2" :content="offerContext" />
          <pre v-if="ready" class="rounded overflow-auto text-xs text-gray-100 p-4 overflow-auto bg-prasset-gray-900 w-full border-0 min-h-16 my-4">
&lt;iframe src="{{ previewUrl }}" width="100%" height="100%" frameborder="0"&gt;&lt;/iframe&gt;
          </pre>
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from '@vue/composition-api';
import Api from '@/providers/api';
import EventBus from '@/eventbus';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import FormFields from '@/fieldsets/offer/formfields';
import { get } from 'lodash';
import { mapCustomFields, clearUnavailableConditionalValues } from '@/providers/helpers';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import MarkdownContent from '@/components/MarkdownContent';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useChangedState from '@/compositions/useChangedState';
import Swatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';


export default {
  name: 'Edit',

  components: {
    Swatches,
    FieldsGenerator,
    LoadingIndicator,
    MarkdownContent,
    Overlay,
    Sidebar,
  },

  setup(props, { root, refs }) {
    const type = 'offercollections';
    const { project_id, entry } = root.$route.params;
    const { changed, watchChanges, commitChanges } = useChangedState();
    const showFrame = ref(true);

    const state = reactive({
      form: {
        options: {
          color: '#FFF',
        },
      },
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    const offerContext = "" + "**Optie 1:**\n" +
     "Met de website module van Prasset kunt u dit aanbod tonen op uw website. Selecteer het aanbod component en kies vervolgens dit specifiek geconfigureerde aanbod.<br /><br />" +
    "**Optie 2:**\n" +
      "Om dit aanbod op andere websites te tonen dan gerealiseerd met Prasset, kunt u dit iframe plaatsen op uw website.";

    const labels = {
      offercollections: root.$tuf('edit_offer'),
    };

    const fields = FormFields(root)[type];
    const endpoint = `projects/${project_id}/${type}/${entry}`;
    const previewUrl = `${process.env.VUE_APP_API_URL}/offerembed/${entry}`;

    /**
     * Map from api functionality.
     */
    function mapFromApi(data) {
      const formData = {};

      // transform input data custom_fields to later apply the field mapping.
      if (get(data, 'custom_fields')) {
        data.custom_fields = mapCustomFields(data.custom_fields);
      }

      fields.map(field => {
        if (field.name && field.type !== 'files') {
          formData[field.name] = get(data, field.map);
        }
      });

      if (get(data, 'options')) {
        formData.options = get(data, 'options');
      }

      if (get(data, 'media')) {
        formData.media = get(data, 'media');
      }

      if (get(data, 'assetgroups')) {
        formData.assetgroups = get(data, 'assetgroups').map(g => g.id);
      }

      if (get(data, 'type')) {
        formData.type = get(data, 'type');
      }
      return formData;
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);
      state.form = mapFromApi(response.data.data);

      state.loading = false;
      state.ready = true;


      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};
      showFrame.value = false;

      try {
        // clone form object.
        let formData = { ...state.form };

        // clear values that are unavailable by conditions, we don't want to keep them.
        formData = clearUnavailableConditionalValues(fields, formData);

        // unset media object from form data
        if (formData.media) {
          delete formData.media;
        }

        const response = await Api().patch(endpoint, {
          ...state.form,
        });

        state.form = mapFromApi(response.data.data);

        root.$notify({ type: 'success', title: root.$tuf('success'), text: root.$tuf('saved') });

        state.loading = false;

        commitChanges(state, 'form');

        // Notify table to update the records.
        EventBus.$emit('record:updated');

        showFrame.value = true;

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        showFrame.value = true;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      offerContext,
      save,
      fields,
      entryId: entry,
      entryType: type,
      title: labels[type],
      previewUrl,
      showFrame,
    };
  },
};
</script>

<template>
  <Sidebar ref="sidebar" :title="title">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1">
        <FieldsGenerator
          v-if="ready"
          :errors="errors"
          :fields="fields"
          :data="form"
          :entryId="entryId"
          :entryType="entryType"
          :fileCollection="form.media"
        />

        <template v-if="entryType === 'projects'">
          <h1>Integraties</h1>
          <p class="text-gray-600">Selecteer externe bronnen om mee te koppelen.</p>

          <div v-if="providers" class="mt-4">
            <div v-for="provider in providers" :key="provider.name">
              <CheckField @change="resetProvider(provider.name)" :value="true" v-model="provider.checked" class="mb-4">
                {{ provider.name }}
              </CheckField>
              <FieldsGenerator
                v-if="provider.checked"
                :errors="provider.errors"
                :fields="provider.fields"
                :data="formProviders[provider.name]"
                :entryId="entryId"
                :entryType="entryType"
              />
            </div>
          </div>
        </template>
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted, set } from '@vue/composition-api';
import { asyncForEach, mapCustomFields } from '@/providers/helpers';
import Api from '@/providers/api';
import CheckField from '@/components/field/Check';
import EventBus from '@/eventbus';
import FormFields from '@/fieldsets/settings/formfields';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import get from 'lodash/get';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'SettingsEdit',

  components: {
    CheckField,
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { root, refs }) {
    const { type, entry } = root.$route.params;
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {},
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
      providers: [],
      formProviders: {},
    });

    const labels = {
      projects: root.$tuf('edit_project'),
      users: root.$tuf('edit_user'),
    };

    const fields = FormFields[type];
    const endpoint = `${type}/${entry}`;

    /**
     * Map from api functionality.
     */
    function mapFromApi(data) {
      const formData = {};

      // transform input data custom_fields to later apply the field mapping.
      if (get(data, 'custom_fields')) {
        data.custom_fields = mapCustomFields(data.custom_fields);
      }

      fields.map(field => {
        if (field.name && field.type !== 'files') {
          formData[field.name] = get(data, field.map);
        }
      });

      if (get(data, 'media')) {
        formData.media = get(data, 'media');
      }

      return formData;
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);
      state.form = mapFromApi(response.data.data);

      if (type === 'projects') {
        await fetchConnections();
      }

      state.loading = false;
      state.ready = true;

      watchChanges(state, ['form', 'formProviders']);
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        // clone form object.
        const formData = { ...state.form };

        // unset media object from form data
        if (formData.media) {
          delete formData.media;
        }

        await Api().patch(endpoint, formData);
        const connectionErrors = await submitConnections();
        if (connectionErrors) {
          state.changed = false;
          state.loading = false;
          root.$notify({ type: 'error', title: 'Fout', text: `Fout bij verbinden van integratie.` });
          return;
        }

        root.$notify({ type: 'success', title: 'Succes', text: `Opgeslagen` });

        state.loading = false;

        commitChanges(state, ['form', 'formProviders']);

        // Notify table to update the records.
        EventBus.$emit('record:updated');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    function resetProvider(providerName) {
      state.providers = state.providers.map(provider => {
        if (provider.name === providerName) {
          provider.errors = {};
        }

        return provider;
      });
    }

    async function fetchConnections() {
      const response = await Api().get(`projects/${entry}/connections/providers`);
      const data = response.data;
      const provs = {};

      data.data.map(row => {
        provs[row.name] = {};
      });

      set(state, 'formProviders', provs);

      state.providers = data.data.map(row => {
        row.checked = false;
        row.credentials = {};
        row.errors = {};

        row.fields = Object.entries(row.fields).map(f => {
          return {
            type: 'text',
            colSpan: 3,
            ...f[1],
          };
        });

        return row;
      });
    }

    async function submitConnections() {
      let failed = false;

      await asyncForEach(state.providers, async (provider) => {
        if (provider.checked) {
          let form = {
            provider: provider.name,
            name: provider.name,
            credentials: {},
            options: {},
          };

          Object.entries(state.formProviders[provider.name]).forEach(e => {
            const name = e[0].split('.')[1];
            const value = e[1];
            form.credentials[name] = value;
            form.options[name] = value;
          });

          try {
            await Api().post(`projects/${entry}/connections`, form);
          } catch (error) {
            failed = true;
            const errorResponse = error.response.data;

            state.providers = state.providers.map(prov => {
              if (prov.name === provider.name) {
                prov.errors = errorResponse.errors ? errorResponse.errors : { message: [errorResponse.message] };
              }
              return prov;
            });
          }
        }
      });

      return failed;
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      fields: FormFields[type],
      entryId: entry,
      entryType: type,
      title: labels[type],
      resetProvider,
    };
  },
};
</script>

<template>
  <Sidebar ref="sidebar" :title="title">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <FieldsGenerator
        v-if="ready"
        :errors="errors"
        :fields="fields"
        :data="form"
        :entryId="entryId"
        :entryType="entryType"
        :fileCollection="form.media"
        class="relative z-1"
      />

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="button"
            class="button button--outlined relative mr-4"
            :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
            :disabled="loading"
            @click="save(false, 'save')"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save' }">
              {{ $tuf('save') }}
            </span>
            <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api';
import Api from '@/providers/api';
import EventBus from '@/eventbus';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import FormFields from '@/fieldsets/crm/formfields';
import get from 'lodash/get';
import { mapCustomFields, clearUnavailableConditionalValues } from '@/providers/helpers';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'Edit',

  components: {
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { root, refs }) {
    const { project_id, type, entry } = root.$route.params;
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {},
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    const labels = {
      people: root.$tuf('edit_person'),
    };

    const fields = FormFields[type];
    const endpoint = `projects/${project_id}/${type}/${entry}`;

    /**
     * Old map from api functionality.
     */
    function mapFromApi(data) {
      const formData = {};

      // transform input data custom_fields to later apply the field mapping.
      if (get(data, 'custom_fields')) {
        data.custom_fields = mapCustomFields(data.custom_fields);
      }

      fields.map(field => {
        if (field.name && field.type !== 'files') {
          formData[field.name] = get(data, field.map);
        }
      });

      if (get(data, 'media')) {
        formData.media = get(data, 'media');
      }

      return formData;
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);
      state.form = mapFromApi(response.data.data);

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        // clone form object.
        let formData = { ...state.form };

        // clear values that are unavailable by conditions, we don't want to keep them.
        formData = clearUnavailableConditionalValues(fields, formData);

        // unset media object from form data
        if (formData.media) {
          delete formData.media;
        }

        await Api().patch(endpoint, formData);

        root.$notify({ type: 'success', title: 'Succes', text: `Opgeslagen` });

        state.loading = false;

        commitChanges(state, 'form');

        // Notify table to update the records.
        EventBus.$emit('record:updated');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      fields: FormFields[type],
      entryId: entry,
      entryType: type,
      title: labels[type],
    };
  },
};
</script>
